//
//
//
//
//
//
//
//
//
//
//

import Logo from '~/assets/img/Logo.svg';
import img500 from '~/assets/img/error-500.svg';

export default {
  name: 'Error500',
  props: {
    error: {
      type: Object,
      default: () => {},
    },
  },
  head() {
    return {
      title: 'Error 500',
    };
  },
  data() {
    return {
      Logo,
      img500,
    };
  },
};
