import Vue from 'vue';
import { COVERAGE_TYPES } from '~/constants/coverage-constants';
import { checkFeatureFlag, FEATURE_TOGGLES_KEYS } from '~/feature-flags/feature-toggles';
import { validateCoverageUseCase } from '~/usecases/address/validate-coverage-usecase';
import { splitStoreUsecase } from '~/usecases/split-store-usecase';

export default async function validateStoreMiddleware({ store, route, redirect, error }) {
  const { getters, dispatch } = store;
  if (route.params.store) {
    const {
      params: { store: storeSlug, city: citySlug },
    } = route;

    const addressObject = getters['address/getAddressObject'];
    const currentCountry = getters['home/getCurrentCountry'];

    if (checkFeatureFlag(FEATURE_TOGGLES_KEYS.SPLIT_STORE_GENERAL_TOGGLE)) {
      await splitStoreUsecase(true);
    }

    const city = getters['home/getCityBySlug'](citySlug);
    if (!(city instanceof Object)) {
      return error({
        statusCode: 404,
      });
    }
    await store.dispatch('home/setStore', city);

    const coverageResponse = await validateCoverageUseCase({
      storeSlug,
      cityId: city.id,
      latitude: addressObject.latitude,
      longitude: addressObject.longitude,
    });

    if (coverageResponse === COVERAGE_TYPES.CURRENT_STORE_NOT_FOUND) {
      return error({
        statusCode: 404,
      });
    } else if (coverageResponse === COVERAGE_TYPES.CURRENT_STORE_WITH_COVERAGE) {
      const temporalStores = getters['home/getTemporalStores'];
      const coverageValidCurrentStore = temporalStores.find((store) => storeSlug === store.slug) ?? null;
      await dispatch('home/setIntentionalStore', null);
      await dispatch('home/setMarketplaceStore', coverageValidCurrentStore);
      await dispatch('home/setStores', [...temporalStores]);
    } else if (
      coverageResponse === COVERAGE_TYPES.CURRENT_STORE_CLOSED ||
      coverageResponse === COVERAGE_TYPES.CURRENT_STORE_WITHOUT_COVERAGE
    ) {
      return redirect(`${currentCountry.urlDomain}`);
    } else if (coverageResponse === COVERAGE_TYPES.WITHOUT_STORE_SELECTED) {
      return redirect(`${currentCountry.urlDomain}`);
    } else if (coverageResponse === COVERAGE_TYPES.STORES_NOT_AVAILABLE) {
      //TODO: @addressFlow en la pagina debe estar la opción de dejar un botón de dejar un comentario
      await redirect(`/error/store-closed`);
    }
  }
}

