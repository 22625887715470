import { optional } from '~/decorators/optional';
import { required } from '~/decorators/required';
import { scheme } from '~/decorators/scheme';

@scheme()
export class StoresParams {
  @required()
  cityId: number;

  @optional()
  latitude: string;

  @optional()
  longitude: string;

  @optional()
  cache: boolean;

  constructor(params: Partial<StoresParams>) {
    Object.assign(this, params);
  }
}
