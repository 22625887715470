/* eslint-disable max-classes-per-file */
import { BigQueryEvent } from './bigquery';

export class HomeOpenedBigQuery extends BigQueryEvent {
  constructor(store) {
    super(store);
    this.name = 'home_opened';
  }
}

export class StoreOpenedBigQuery extends BigQueryEvent {
  constructor(store) {
    super(store);
    this.name = 'store_opened';
  }
}

export class StoreSelectedBigQuery extends BigQueryEvent {
  constructor(store) {
    super(store);
    this.name = 'store_selected';
  }
}

export class DepartmentViewedBigQuery extends BigQueryEvent {
  constructor(store) {
    super(store);
    this.name = 'department_viewed';
  }
}

export class ShelfClickedBigQuery extends BigQueryEvent {
  constructor(store) {
    super(store);
    this.name = 'shelft_clicked';
  }
}

export class AddressInputViewedBigQuery extends BigQueryEvent {
  constructor(store) {
    super(store);
    this.name = 'address_input_viewed';
  }
}

export class AddressEnteredBigQuery extends BigQueryEvent {
  constructor(store) {
    super(store);
    this.name = 'address_entered';
  }
}

export class AddressSelectedFromZonesBigQuery extends BigQueryEvent {
  constructor(store) {
    super(store);
    this.name = 'address_selected_from_zones';
  }
}

export class AddressValidatedBigQuery extends BigQueryEvent {
  constructor(store) {
    super(store);
    this.name = 'address_validated';
  }
}

export class ProductAddedBigQuery extends BigQueryEvent {
  constructor(store) {
    super(store);
    this.name = 'product_added';
  }
}

export class ProductAddedFromPastOrderBigQuery extends BigQueryEvent {
  constructor(store) {
    super(store);
    this.name = 'products_added_from_past_order';
  }
}

export class CartViewedBigQuery extends BigQueryEvent {
  constructor(store) {
    super(store);
    this.name = 'cart_viewed';
  }
}

export class CheckoutStartedBigQuery extends BigQueryEvent {
  constructor(store) {
    super(store);
    this.name = 'checkout_started';
  }
}

export class OrderCompletedBigQuery extends BigQueryEvent {
  constructor(store) {
    super(store);
    this.name = 'order_completed';
  }
}

export class ProductPurchasedBigQuery extends BigQueryEvent {
  constructor(store) {
    super(store);
    this.name = 'product_purchased';
  }
}

export class CartDeletedBigQuery extends BigQueryEvent {
  constructor(store) {
    super(store);
    this.name = 'cart_deleted';
  }
}

export class ProductViewedBigQuery extends BigQueryEvent {
  constructor(store) {
    super(store);
    this.name = 'product_viewed';
  }
}

export class WidgetAccountClickedBigQuery extends BigQueryEvent {
  constructor(store) {
    super(store);
    this.name = 'widget_account_clicked';
  }
}

export class AddressesViewedByWidgetAccountBigQuery extends BigQueryEvent {
  constructor(store) {
    super(store);
    this.name = 'addresses_viewed_by_widget_account';
  }
}

export class OrdersViewedByWidgetAccountBigQuery extends BigQueryEvent {
  constructor(store) {
    super(store);
    this.name = 'orders_viewed_by_widget_account';
  }
}

export class PaymentViewedByWidgetAccountBigQuery extends BigQueryEvent {
  constructor(store) {
    super(store);
    this.name = 'payment_methods_viewed_by_widget_account';
  }
}

export class CreditsViewedByWidgetAccountBigQuery extends BigQueryEvent {
  constructor(store) {
    super(store);
    this.name = 'credits_viewed_by_widget_account';
  }
}

export class AddressesViewedByAccountBigQuery extends BigQueryEvent {
  constructor(store) {
    super(store);
    this.name = 'addresses_viewed_by_account';
  }
}

export class OrdersViewedByAccountBigQuery extends BigQueryEvent {
  constructor(store) {
    super(store);
    this.name = 'orders_viewed_by_account';
  }
}

export class PaymentViewedByAccountBigQuery extends BigQueryEvent {
  constructor(store) {
    super(store);
    this.name = 'payment_methods_viewed_by_account';
  }
}

export class CreditsViewedByAccountBigQuery extends BigQueryEvent {
  constructor(store) {
    super(store);
    this.name = 'credits_viewed_by_account';
  }
}

export class OrdersViewedBigQuery extends BigQueryEvent {
  constructor(store) {
    super(store);
    this.name = 'orders_viewed';
  }
}

export class ProductsSearchedBigQuery extends BigQueryEvent {
  constructor(store) {
    super(store);
    this.name = 'products_searched';
  }
}

export class AddressInputViewedByAccountBigQuery extends BigQueryEvent {
  constructor(store) {
    super(store);
    this.name = 'address_input_viewed_by_account';
  }
}

export class AddressEditedByAccountBigQuery extends BigQueryEvent {
  constructor(store) {
    super(store);
    this.name = 'address_edited_by_account';
  }
}

export class AddressDeletedByAccountBigQuery extends BigQueryEvent {
  constructor(store) {
    super(store);
    this.name = 'address_deleted_by_account';
  }
}

export class AddressUpdatedByAccountBigQuery extends BigQueryEvent {
  constructor(store) {
    super(store);
    this.name = 'address_updated_by_account';
  }
}

export class BillViewedBigQuery extends BigQueryEvent {
  constructor(store) {
    super(store);
    this.name = 'bill_viewed';
  }
}

export class LastProductsBoughtViewedBigQuery extends BigQueryEvent {
  constructor(store) {
    super(store);
    this.name = 'last_products_bought_viewed';
  }
}

export class FrequentlyAskedQuestionsBigQuery extends BigQueryEvent {
  constructor(store) {
    super(store);
    this.name = 'frequently_asked_questions';
  }
}

export class ProductDealsOpenedBigQuery extends BigQueryEvent {
  constructor(store) {
    super(store);
    this.name = 'product_deals_opened';
  }
}

export class ProductRemovedBigQuery extends BigQueryEvent {
  constructor(store) {
    super(store);
    this.name = 'product_removed';
  }
}

export class SuggestedProductViewedBigQuery extends BigQueryEvent {
  constructor(store) {
    super(store);
    this.name = 'suggested_product_viewed';
  }
}

export class AddressDetailsEnteredBigQuery extends BigQueryEvent {
  constructor(store) {
    super(store);
    this.name = 'address_details_entered';
  }
}

export class DeliveryDatetimeSavedBigQuery extends BigQueryEvent {
  constructor(store) {
    super(store);
    this.name = 'delivery_datetime_saved';
  }
}

export class PaymentMethodCofirmedBigQuery extends BigQueryEvent {
  constructor(store) {
    super(store);
    this.name = 'payment_method_cofirmed';
  }
}

export class CreditCardInfoEnteredBigQuery extends BigQueryEvent {
  constructor(store) {
    super(store);
    this.name = 'credit_card_info_entered';
  }
}

export class BannerViewedBigQuery extends BigQueryEvent {
  constructor(store) {
    super(store);
    this.name = 'banner_viewed';
  }
}

export class BannerClickedBigQuery extends BigQueryEvent {
  constructor(store) {
    super(store);
    this.name = 'banner_clicked';
  }
}

export class PromotionsViewedBigQuery extends BigQueryEvent {
  constructor(store) {
    super(store);
    this.name = 'promotions_viewed';
  }
}

export class checkoutErrorBigQuery extends BigQueryEvent {
  constructor(store) {
    super(store);
    this.name = 'checkout_error';
  }
}

export class OrderStatusViewedBigQuery extends BigQueryEvent {
  constructor(store) {
    super(store);
    this.name = 'order_status_viewed';
  }
}

export class CreditCardAddedByAccountBigQuery extends BigQueryEvent {
  constructor(store) {
    super(store);
    this.name = 'credit_card_added_by_account';
  }
}

export class CreditCardDeletedByAccountBigQuery extends BigQueryEvent {
  constructor(store) {
    super(store);
    this.name = 'credit_card_deleted_by_account';
  }
}

export class CreditCardEnteredByAccountBigQuery extends BigQueryEvent {
  constructor(store) {
    super(store);
    this.name = 'credit_card_entered_by_account';
  }
}

export class ReferralCodeCopiedBigQuery extends BigQueryEvent {
  constructor(store) {
    super(store);
    this.name = 'referral_code_copied';
  }
}

export class ReferralCodeSharedBigQuery extends BigQueryEvent {
  constructor(store) {
    super(store);
    this.name = 'referral_code_shared';
  }
}

export class OldToNewTransitionBigQuery extends BigQueryEvent {
  constructor(store) {
    super(store);
    this.name = 'old_to_new_transition';
  }
}

export class DownloadAppWebBigQuery extends BigQueryEvent {
  constructor(store) {
    super(store);
    this.name = 'download_app_web';
  }
}

export class ServiceProposalImpresionBigQuery extends BigQueryEvent {
  constructor(store) {
    super(store);
    this.name = 'service_proposal_impresion';
  }
}

export class ServiceProposalResponseBigQuery extends BigQueryEvent {
  constructor(store) {
    super(store);
    this.name = 'service_proposal_response';
  }
}

export class ServiceProposalClosedBigQuery extends BigQueryEvent {
  constructor(store) {
    super(store);
    this.name = 'service_proposal_closed';
  }
}

export class ProductViewedSponsoredBigQuery extends BigQueryEvent {
  constructor(store) {
    super(store);
    this.name = 'product_viewed_sponsored';
  }
}

export class AddressMapViewed extends BigQueryEvent {
  constructor(store) {
    super(store);
    this.name = 'address_map_viewed';
  }
}

export class AddressConfirmed extends BigQueryEvent {
  constructor(store) {
    super(store);
    this.name = 'address_confirmed';
  }
}
export class NextAvailableDeliverySlotAlert extends BigQueryEvent {
  constructor(store) {
    super(store);
    this.name = 'next_available_delivery_slot_alert';
  }
}
export class UserMenuSelected extends BigQueryEvent {
  constructor(store) {
    super(store);
    this.name = 'user_menu_selected';
  }
}
export class HomeOptionSelected extends BigQueryEvent {
  constructor(store) {
    super(store);
    this.name = 'home_option_selected';
  }
}
export class AddressSelectedBigQuery extends BigQueryEvent {
  constructor(store) {
    super(store);
    this.name = 'address_selected';
  }
}
export class CitySelected extends BigQueryEvent {
  constructor(store) {
    super(store);
    this.name = 'city_selected';
  }
}
export class CountrySelected extends BigQueryEvent {
  constructor(store) {
    super(store);
    this.name = 'country_selected';
  }
}

export class ProductsListViewed extends BigQueryEvent {
  constructor(store) {
    super(store);
    this.name = 'products_list_viewed';
  }
}

export class NoCoverage extends BigQueryEvent {
  constructor(store) {
    super(store);
    this.name = 'no_coverage';
  }
}

export class AddressTyped extends BigQueryEvent {
  constructor(store) {
    super(store);
    this.name = 'address_typed';
  }
}

export class EditPersonalDataBigQuery extends BigQueryEvent {
  constructor(store) {
    super(store);
    this.name = 'edit_personal_data';
  }
}

export class EditPersonalDataNameBigQuery extends BigQueryEvent {
  constructor(store) {
    super(store);
    this.name = 'edit_personal_data_name';
  }
}

export class EditPersonalDataMailBigQuery extends BigQueryEvent {
  constructor(store) {
    super(store);
    this.name = 'edit_personal_data_mail';
  }
}

export class EditPersonalDataPhoneBigQuery extends BigQueryEvent {
  constructor(store) {
    super(store);
    this.name = 'edit_personal_data_phone';
  }
}
export class DeliveryDatetimeSelected extends BigQueryEvent {
  constructor(store) {
    super(store);
    this.name = 'delivery_datetime_selected';
  }
}

export class AdvertisingPreferencesBigQuery extends BigQueryEvent {
  constructor(store) {
    super(store);
    this.name = 'advertising_preferences';
  }
}

export class AdvertisingPreferencesOnBigQuery extends BigQueryEvent {
  constructor(store) {
    super(store);
    this.name = 'advertising_preferences_on';
  }
}

export class AdvertisingPreferencesOffBigQuery extends BigQueryEvent {
  constructor(store) {
    super(store);
    this.name = 'advertising_preferences_off';
  }
}

export class AdvertisingPreferencesOffConfirmedBigQuery extends BigQueryEvent {
  constructor(store) {
    super(store);
    this.name = 'advertising_preferences_off_confirmed ';
  }
}
export class PaymentMethodViewed extends BigQueryEvent {
  constructor(store) {
    super(store);
    this.name = 'payment_method_viewed';
  }
}

export class PaymentMethodSelected extends BigQueryEvent {
  constructor(store) {
    super(store);
    this.name = 'payment_method_selected';
  }
}

export class ProductChangeCheckoutBigQuery extends BigQueryEvent {
  constructor(store) {
    super(store);
    this.name = 'product_change_checkout';
  }
}

export class ProductChangeModifiedBigQuery extends BigQueryEvent {
  constructor(store) {
    super(store);
    this.name = 'product_change_modified';
  }
}

export class DeliveryDatetimeViewShown extends BigQueryEvent {
  constructor(store) {
    super(store);
    this.name = 'delivery_datetime_view_shown';
  }
}

export class ShippingDetailsEntered extends BigQueryEvent {
  constructor(store) {
    super(store);
    this.name = 'shipping_details_entered';
  }
}

export class ChannelClicked extends BigQueryEvent {
  constructor(store) {
    super(store);
    this.name = 'channel_clicked';
  }
}

export class StoryViewed extends BigQueryEvent {
  constructor(store) {
    super(store);
    this.name = 'story_viewed';
  }
}

export class StoryCtaClicked extends BigQueryEvent {
  constructor(store) {
    super(store);
    this.name = 'story_cta_clicked';
  }
}

export class StoryProfileClicked extends BigQueryEvent {
  constructor(store) {
    super(store);
    this.name = 'story_profile_clicked';
  }
}

export class StoryExited extends BigQueryEvent {
  constructor(store) {
    super(store);
    this.name = 'story_exited';
  }
}

export class StoryError extends BigQueryEvent {
  constructor(store) {
    super(store);
    this.name = 'story_error';
  }
}

export class LastStoryFinished extends BigQueryEvent {
  constructor(store) {
    super(store);
    this.name = 'last_story_finished';
  }
}

export class UserLogin extends BigQueryEvent {
  constructor(store) {
    super(store);
    this.name = 'user_login';
  }
}

export class SortBySelected extends BigQueryEvent {
  constructor(store) {
    super(store);
    this.name = 'sort_by_selected';
  }
}

export class PrivacyPolicyViewed extends BigQueryEvent {
  constructor(store) {
    super(store);
    this.name = 'privacy_policy_viewed';
  }
}

export class TermsAndConditionsViewed extends BigQueryEvent {
  constructor(store) {
    super(store);
    this.name = 'terms_and_conditions_viewed';
  }
}

export class AddressList extends BigQueryEvent {
  constructor(store) {
    super(store);
    this.name = 'address_list';
  }
}

export class CellphoneLoginVerification extends BigQueryEvent {
  constructor(store) {
    super(store);
    this.name = 'cellphone_login_verification';
  }
}

export class NoVerificationCode extends BigQueryEvent {
  constructor(store) {
    super(store);
    this.name = 'no_verification_code';
  }
}

export class UserLogout extends BigQueryEvent {
  constructor(store) {
    super(store);
    this.name = 'user_logout';
  }
}
export class RecoverySend extends BigQueryEvent {
  constructor(store) {
    super(store);
    this.name = 'recovery_send';
  }
}

export class PasswordRecovery extends BigQueryEvent {
  constructor(store) {
    super(store);
    this.name = 'password_recovery';
  }
}

export class CellphoneLoginMethod extends BigQueryEvent {
  constructor(store) {
    super(store);
    this.name = 'cellphone_login_method';
  }
}

export class EmailLogin extends BigQueryEvent {
  constructor(store) {
    super(store);
    this.name = 'email_login';
  }
}

export class SupportClicked extends BigQueryEvent {
  constructor(store) {
    super(store);
    this.name = 'support_clicked';
  }
}

export class DeleteAccount extends BigQueryEvent {
  constructor(store) {
    super(store);
    this.name = 'delete_account';
  }
}

export class DeleteAccountConfirmed extends BigQueryEvent {
  constructor(store) {
    super(store);
    this.name = 'delete_account_confirmed';
  }
}

export class CreditCardNumberAdded extends BigQueryEvent {
  constructor(store) {
    super(store);
    this.name = 'credit_card_number_added';
  }
}

export class CreditCardNameAdded extends BigQueryEvent {
  constructor(store) {
    super(store);
    this.name = 'credit_card_name_added';
  }
}

export class UserDetailsViewed extends BigQueryEvent {
  constructor(store) {
    super(store);
    this.name = 'user_details_viewed';
  }
}

export class OrdersListViewed extends BigQueryEvent {
  constructor(store) {
    super(store);
    this.name = 'orders_list_viewed';
  }
}
export class BrandRoomViewed extends BigQueryEvent {
  constructor(store) {
    super(store);
    this.name = 'brand_room_viewed';
  }
}

export class PrivacyPolicyAccepted extends BigQueryEvent {
  constructor(store) {
    super(store);
    this.name = 'privacy_policy_accepted';
  }
}

export class PrivacyPolicyClicked extends BigQueryEvent {
  constructor(store) {
    super(store);
    this.name = 'privacy_policy_clicked';
  }
}

export class VideoBannerViewed extends BigQueryEvent {
  constructor(store) {
    super(store);
    this.name = 'video_banner_viewed';
  }
}

export class VideoBannerExited extends BigQueryEvent {
  constructor(store) {
    super(store);
    this.name = 'video_banner_exited';
  }
}

export class VideoBannerCtaClicked extends BigQueryEvent {
  constructor(store) {
    super(store);
    this.name = 'video_banner_cta_clicked';
  }
}

export class ProductVideoViewed extends BigQueryEvent {
  constructor(store) {
    super(store);
    this.name = 'product_video_viewed';
  }
}

export class ProductVideoCtaClicked extends BigQueryEvent {
  constructor(store) {
    super(store);
    this.name = 'product_video_cta_clicked';
  }
}

export class ProductVideoExited extends BigQueryEvent {
  constructor(store) {
    super(store);
    this.name = 'product_video_exited';
  }
}
export class StoryShared extends BigQueryEvent {
  constructor(store) {
    super(store);
    this.name = 'story_shared';
  }
}
export class OrderDetailViewed extends BigQueryEvent {
  constructor(store) {
    super(store);
    this.name = 'order_detail_viewed';
  }
}
export class CreditsViewed extends BigQueryEvent {
  constructor(store) {
    super(store);
    this.name = 'credits_viewed';
  }
}
export class CreditCardAdded extends BigQueryEvent {
  constructor(store) {
    super(store);
    this.name = 'credit_card_added';
  }
}
export class CreditCardRemoved extends BigQueryEvent {
  constructor(store) {
    super(store);
    this.name = 'credit_card_removed';
  }
}
export class AdressRemoved extends BigQueryEvent {
  constructor(store) {
    super(store);
    this.name = 'adress_removed';
  }
}

export class SavingModalViewed extends BigQueryEvent {
  constructor(store) {
    super(store);
    this.name = 'saving_modal_viewed';
  }
}
export class AveragePriceOtherStores extends BigQueryEvent {
  constructor(store) {
    super(store);
    this.name = 'average_price_other_stores';
  }
}

export class DeepLinkNotFound extends BigQueryEvent {
  constructor(store) {
    super(store);
    this.name = 'deeplink_not_found';
  }
}

export class PrizeClicked extends BigQueryEvent {
  constructor(store) {
    super(store);
    this.name = 'prize_clicked';
  }
}

export class InfoCampaigns extends BigQueryEvent {
  constructor(store) {
    super(store);
    this.name = 'info_campaigns';
  }
}

export class GiftProgressTrackerClicked extends BigQueryEvent {
  constructor(store) {
    super(store);
    this.name = 'gift_progress_tracker_clicked';
  }
}

export class ConfirmOrderViewed extends BigQueryEvent {
  constructor(store) {
    super(store);
    this.name = 'confirm_order_viewed';
  }
}
export class RefundRequestSelected extends BigQueryEvent {
  constructor(store) {
    super(store);
    this.name = 'refund_request_selected';
  }
}

export class RefundRequestSend extends BigQueryEvent {
  constructor(store) {
    super(store);
    this.name = 'refund_request_sent';
  }
}
// HelpCenter Events
export class HelpClicked extends BigQueryEvent {
  constructor(store) {
    super(store);
    this.name = 'help_clicked';
  }
}

export class DifferentProblem extends BigQueryEvent {
  constructor(store) {
    super(store);
    this.name = 'different_problem';
  }
}

export class CanceledOrder extends BigQueryEvent {
  constructor(store) {
    super(store);
    this.name = 'canceled_order';
  }
}

export class CancelOrderSac extends BigQueryEvent {
  constructor(store) {
    super(store);
    this.name = 'cancel_order_sac';
  }
}
export class CanceledOrderOption extends BigQueryEvent {
  constructor(store) {
    super(store);
    this.name = 'canceled_order_option';
  }
}

export class LateOrder extends BigQueryEvent {
  constructor(store) {
    super(store);
    this.name = 'late_order';
  }
}

export class ChangeDeliveryDatetime extends BigQueryEvent {
  constructor(store) {
    super(store);
    this.name = 'change_delivery_datetime';
  }
}

export class ChangeDeliveryDatetimeOption extends BigQueryEvent {
  constructor(store) {
    super(store);
    this.name = 'change_delivery_datetime_option';
  }
}
export class ChangeDeliveryDatetimeSelected extends BigQueryEvent {
  constructor(store) {
    super(store);
    this.name = 'change_delivery_datetime_selected';
  }
}

export class ChangePaymentMethod extends BigQueryEvent {
  constructor(store) {
    super(store);
    this.name = 'change_payment_method';
  }
}

export class ChangePaymentMethodOption extends BigQueryEvent {
  constructor(store) {
    super(store);
    this.name = 'change_payment_method_option';
  }
}
export class SupportOptionSent extends BigQueryEvent {
  constructor(store) {
    super(store);
    this.name = 'support_option_sent';
  }
}

export class ForgottenCoupon extends BigQueryEvent {
  constructor(store) {
    super(store);
    this.name = 'forgotten_coupon';
  }
}

export class ForgottenCouponRedeemed extends BigQueryEvent {
  constructor(store) {
    super(store);
    this.name = 'forgotten_coupon_redeemed';
  }
}

export class DeliveredServiceDriverSac extends BigQueryEvent {
  constructor(store) {
    super(store);
    this.name = 'delivered_service_driver_sac';
  }
}

export class DeliveredWrongAmountSac extends BigQueryEvent {
  constructor(store) {
    super(store);
    this.name = 'delivered_wrong_amount_sac';
  }
}

export class DeliveredOrderNeverArrivedSac extends BigQueryEvent {
  constructor(store) {
    super(store);
    this.name = 'delivered_order_never_arrived_sac';
  }
}
export class DeliveredOrderProductsSac extends BigQueryEvent {
  constructor(store) {
    super(store);
    this.name = 'delivered_order_products_sac';
  }
}

export class DeliveredOtherSac extends BigQueryEvent {
  constructor(store) {
    super(store);
    this.name = 'delivered_other_sac';
  }
}
export class InvoiceToCompany extends BigQueryEvent {
  constructor(store) {
    super(store);
    this.name = 'invoice_to_company';
  }
}

export class HelpCenterHomeViewed extends BigQueryEvent {
  constructor(store) {
    super(store);
    this.name = 'help_center_home_viewed';
  }
}
export class ProductChangeConfirmed extends BigQueryEvent {
  constructor(store) {
    super(store);
    this.name = 'product_change_confirmed';
  }
}
export class AddressChange extends BigQueryEvent {
  constructor(store) {
    super(store);
    this.name = 'address_change';
  }
}

export class StreetNumberEdited extends BigQueryEvent {
  constructor(store) {
    super(store);
    this.name = 'street_number_edited';
  }
}

export class addProductGroup extends BigQueryEvent {
  constructor(store) {
    super(store);
    this.name = 'add_product_group';
  }
}
export class GeolocationClicked extends BigQueryEvent {
  constructor(store) {
    super(store);
    this.name = 'geolocation_clicked';
  }
}

export class GeolocationSelected extends BigQueryEvent {
  constructor(store) {
    super(store);
    this.name = 'geolocation_selected';
  }
}

export class GeolocationViewed extends BigQueryEvent {
  constructor(store) {
    super(store);
    this.name = 'geolocation_viewed';
  }
}
export class StoriesInfoClicked extends BigQueryEvent {
  constructor(store) {
    super(store);
    this.name = 'stories_info_clicked';
  }
}

export class SellersInfoClicked extends BigQueryEvent {
  constructor(store) {
    super(store);
    this.name = 'sellers_info_clicked';
  }
}

export class ReceiveBySelected extends BigQueryEvent {
  constructor(store) {
    super(store);
    this.name = 'received_by_selected';
  }
}
export class ReceivedByOtherPersonConfirmed extends BigQueryEvent {
  constructor(store) {
    super(store);
    this.name = 'received_by_other_person_confirmed';
  }
}
export class CreditCardAddAttempt extends BigQueryEvent {
  constructor(store) {
    super(store);
    this.name = 'credit_card_add_attempt';
  }
}

export class PrimeOptionSelected extends BigQueryEvent {
  constructor(store) {
    super(store);
    this.name = 'prime_option_selected';
  }
}

export class PrimeAvailablePlans extends BigQueryEvent {
  constructor(store) {
    super(store);
    this.name = 'prime_available_plans';
  }
}

export class PrimePlansViewed extends BigQueryEvent {
  constructor(store) {
    super(store);
    this.name = 'prime_plans_viewed';
  }
}

export class OrderDetailHcChat extends BigQueryEvent {
  constructor(store) {
    super(store);
    this.name = 'orderdetail_hc_chat';
  }
}

export class CloseChat extends BigQueryEvent {
  constructor(store) {
    super(store);
    this.name = 'close_chat';
  }
}

export class ChatForm extends BigQueryEvent {
  constructor(store) {
    super(store);
    this.name = 'chat_form';
  }
}

export class LiveChat extends BigQueryEvent {
  constructor(store) {
    super(store);
    this.name = 'live_chat';
  }
}

export class ReturnToOrder extends BigQueryEvent {
  constructor(store) {
    super(store);
    this.name = 'return_to_order';
  }
}
export class PrimePlanSelected extends BigQueryEvent {
  constructor(store) {
    super(store);
    this.name = 'prime_plan_selected';
  }
}

export class PrimeSubscriptionSummary extends BigQueryEvent {
  constructor(store) {
    super(store);
    this.name = 'prime_subscription_summary';
  }
}

export class PrimeSubscriptionSummaryViewed extends BigQueryEvent {
  constructor(store) {
    super(store);
    this.name = 'prime_subscription_summary_viewed';
  }
}

export class PrimeSelectPaymentMethodClicked extends BigQueryEvent {
  constructor(store) {
    super(store);
    this.name = 'prime_select_payment_method_clicked';
  }
}

export class CreditCardExpirationDateAdded extends BigQueryEvent {
  constructor(store) {
    super(store);
    this.name = 'credit_card_expiration_date_added';
  }
}

export class CreditCardCvvAdded extends BigQueryEvent {
  constructor(store) {
    super(store);
    this.name = 'credit_card_cvv_added';
  }
}

export class CreditCardIdAdded extends BigQueryEvent {
  constructor(store) {
    super(store);
    this.name = 'credit_card_id_added';
  }
}

export class PrimeBillingFormViewed extends BigQueryEvent {
  constructor(store) {
    super(store);
    this.name = 'prime_billing_form_viewed';
  }
}

export class PrimePaymentOk extends BigQueryEvent {
  constructor(store) {
    super(store);
    this.name = 'prime_payment_ok';
  }
}

export class PrimePaymentFailed extends BigQueryEvent {
  constructor(store) {
    super(store);
    this.name = 'prime_payment_failed';
  }
}

export class PrimeSubscriptionConfirmation extends BigQueryEvent {
  constructor(store) {
    super(store);
    this.name = 'prime_subscription_confirmation';
  }
}

export class PrimeConfirmationViewed extends BigQueryEvent {
  constructor(store) {
    super(store);
    this.name = 'prime_confirmation_viewed';
  }
}

export class GoToHomeClicked extends BigQueryEvent {
  constructor(store) {
    super(store);
    this.name = 'go_to_home_clicked';
  }
}

export class GoToSubscriptionInfoClicked extends BigQueryEvent {
  constructor(store) {
    super(store);
    this.name = 'go_to_subscription_info_clicked';
  }
}

export class PrimeSubscriptionInfo extends BigQueryEvent {
  constructor(store) {
    super(store);
    this.name = 'prime_subscription_info';
  }
}

export class PrimeInfoViewed extends BigQueryEvent {
  constructor(store) {
    super(store);
    this.name = 'prime_info_viewed';
  }
}

export class PrimeCancellationStarted extends BigQueryEvent {
  constructor(store) {
    super(store);
    this.name = 'prime_cancellation_started';
  }
}

export class PrimeCancellationConfirmed extends BigQueryEvent {
  constructor(store) {
    super(store);
    this.name = 'prime_cancellation_confirmed';
  }
}

export class PrimeTrialViewed extends BigQueryEvent {
  constructor(store) {
    super(store);
    this.name = 'prime_trial_viewed';
  }
}

export class PrimeTrialSelected extends BigQueryEvent {
  constructor(store) {
    super(store);
    this.name = 'prime_trial_selected';
  }
}

export class PrimePaymentAttempted extends BigQueryEvent {
  constructor(store) {
    super(store);
    this.name = 'prime_payment_attempted';
  }
}

export class SurveySentChat extends BigQueryEvent {
  constructor(store) {
    super(store);
    this.name = 'survey_sent_chat';
  }
}

export class SurveyOmissionChat extends BigQueryEvent {
  constructor(store) {
    super(store);
    this.name = 'survey_omission_chat';
  }
}
