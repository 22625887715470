import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _98957f1e = () => interopDefault(import('../client/pages/checkout/index.vue' /* webpackChunkName: "pages/checkout/index" */))
const _70481dc4 = () => interopDefault(import('../client/pages/faq.vue' /* webpackChunkName: "pages/faq" */))
const _88a3d01e = () => interopDefault(import('../client/pages/login.vue' /* webpackChunkName: "pages/login" */))
const _03d85eb7 = () => interopDefault(import('../client/pages/my-account/index.vue' /* webpackChunkName: "pages/my-account/index" */))
const _1dd4854a = () => interopDefault(import('../client/pages/prime/index.vue' /* webpackChunkName: "pages/prime/index" */))
const _2ae87b72 = () => interopDefault(import('../client/pages/referidos.vue' /* webpackChunkName: "pages/referidos" */))
const _52025714 = () => interopDefault(import('../client/pages/sign-up/index.vue' /* webpackChunkName: "pages/sign-up/index" */))
const _6a12077e = () => interopDefault(import('../client/pages/checkout/campaign.vue' /* webpackChunkName: "pages/checkout/campaign" */))
const _2e7729e5 = () => interopDefault(import('../client/pages/checkout/payment.vue' /* webpackChunkName: "pages/checkout/payment" */))
const _0ce7b442 = () => interopDefault(import('../client/pages/checkout/receive.vue' /* webpackChunkName: "pages/checkout/receive" */))
const _3eaef162 = () => interopDefault(import('../client/pages/checkout/success.vue' /* webpackChunkName: "pages/checkout/success" */))
const _0c049199 = () => interopDefault(import('../client/pages/my-account/address.vue' /* webpackChunkName: "pages/my-account/address" */))
const _6727336e = () => interopDefault(import('../client/pages/my-account/advertising.vue' /* webpackChunkName: "pages/my-account/advertising" */))
const _4d13e73f = () => interopDefault(import('../client/pages/my-account/credits.vue' /* webpackChunkName: "pages/my-account/credits" */))
const _7b6c6850 = () => interopDefault(import('../client/pages/my-account/orders.vue' /* webpackChunkName: "pages/my-account/orders" */))
const _c33d7fe0 = () => interopDefault(import('../client/pages/my-account/payment-methods.vue' /* webpackChunkName: "pages/my-account/payment-methods" */))
const _2bdb312e = () => interopDefault(import('../client/pages/my-account/your-data.vue' /* webpackChunkName: "pages/my-account/your-data" */))
const _2699a58f = () => interopDefault(import('../client/pages/prime/history/index.vue' /* webpackChunkName: "pages/prime/history/index" */))
const _35114c81 = () => interopDefault(import('../client/pages/prime/payment/index.vue' /* webpackChunkName: "pages/prime/payment/index" */))
const _66fa7b84 = () => interopDefault(import('../client/pages/prime/success/index.vue' /* webpackChunkName: "pages/prime/success/index" */))
const _9438cfb2 = () => interopDefault(import('../client/pages/sign-up/success.vue' /* webpackChunkName: "pages/sign-up/success" */))
const _24b6cca0 = () => interopDefault(import('../client/pages/prime/payment/add-card/index.vue' /* webpackChunkName: "pages/prime/payment/add-card/index" */))
const _1e1fe320 = () => interopDefault(import('../client/pages/prime/payment/billing-data/index.vue' /* webpackChunkName: "pages/prime/payment/billing-data/index" */))
const _5d2e4c5d = () => interopDefault(import('../client/pages/error/_type/index.vue' /* webpackChunkName: "pages/error/_type/index" */))
const _1ba41b1f = () => interopDefault(import('../client/pages/l/_id.vue' /* webpackChunkName: "pages/l/_id" */))
const _335837f3 = () => interopDefault(import('../client/pages/sign-up/_code/index.vue' /* webpackChunkName: "pages/sign-up/_code/index" */))
const _1bf3164c = () => interopDefault(import('../client/pages/index.vue' /* webpackChunkName: "pages/index" */))
const _7c05b956 = () => interopDefault(import('../client/pages/_city/index.vue' /* webpackChunkName: "pages/_city/index" */))
const _3e248132 = () => interopDefault(import('../client/pages/_city/_store/index.vue' /* webpackChunkName: "pages/_city/_store/index" */))
const _3342d7ea = () => interopDefault(import('../client/pages/_city/_store/banners/index.vue' /* webpackChunkName: "pages/_city/_store/banners/index" */))
const _d5a7c67e = () => interopDefault(import('../client/pages/_city/_store/productos-recomendados/index.vue' /* webpackChunkName: "pages/_city/_store/productos-recomendados/index" */))
const _1bb9edd5 = () => interopDefault(import('../client/pages/_city/_store/banners/_id/index.vue' /* webpackChunkName: "pages/_city/_store/banners/_id/index" */))
const _7779ee5c = () => interopDefault(import('../client/pages/_city/_store/search/_q/index.vue' /* webpackChunkName: "pages/_city/_store/search/_q/index" */))
const _354b3372 = () => interopDefault(import('../client/pages/_city/_store/banners/_id/_campaign.vue' /* webpackChunkName: "pages/_city/_store/banners/_id/_campaign" */))
const _5c898cd8 = () => interopDefault(import('../client/pages/_city/_store/_department/index.vue' /* webpackChunkName: "pages/_city/_store/_department/index" */))
const _0622d41c = () => interopDefault(import('../client/pages/_city/_store/_department/_shelf/index.vue' /* webpackChunkName: "pages/_city/_store/_department/_shelf/index" */))
const _8f80d54e = () => interopDefault(import('../client/pages/_city/_store/_department/_shelf/_product/index.vue' /* webpackChunkName: "pages/_city/_store/_department/_shelf/_product/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/checkout",
    component: _98957f1e,
    name: "checkout"
  }, {
    path: "/faq",
    component: _70481dc4,
    name: "faq"
  }, {
    path: "/login",
    component: _88a3d01e,
    name: "login"
  }, {
    path: "/my-account",
    component: _03d85eb7,
    name: "my-account"
  }, {
    path: "/prime",
    component: _1dd4854a,
    name: "prime"
  }, {
    path: "/referidos",
    component: _2ae87b72,
    name: "referidos"
  }, {
    path: "/sign-up",
    component: _52025714,
    name: "sign-up"
  }, {
    path: "/checkout/campaign",
    component: _6a12077e,
    name: "checkout-campaign"
  }, {
    path: "/checkout/payment",
    component: _2e7729e5,
    name: "checkout-payment"
  }, {
    path: "/checkout/receive",
    component: _0ce7b442,
    name: "checkout-receive"
  }, {
    path: "/checkout/success",
    component: _3eaef162,
    name: "checkout-success"
  }, {
    path: "/my-account/address",
    component: _0c049199,
    name: "my-account-address"
  }, {
    path: "/my-account/advertising",
    component: _6727336e,
    name: "my-account-advertising"
  }, {
    path: "/my-account/credits",
    component: _4d13e73f,
    name: "my-account-credits"
  }, {
    path: "/my-account/orders",
    component: _7b6c6850,
    name: "my-account-orders"
  }, {
    path: "/my-account/payment-methods",
    component: _c33d7fe0,
    name: "my-account-payment-methods"
  }, {
    path: "/my-account/your-data",
    component: _2bdb312e,
    name: "my-account-your-data"
  }, {
    path: "/prime/history",
    component: _2699a58f,
    name: "prime-history"
  }, {
    path: "/prime/payment",
    component: _35114c81,
    name: "prime-payment"
  }, {
    path: "/prime/success",
    component: _66fa7b84,
    name: "prime-success"
  }, {
    path: "/sign-up/success",
    component: _9438cfb2,
    name: "sign-up-success"
  }, {
    path: "/prime/payment/add-card",
    component: _24b6cca0,
    name: "prime-payment-add-card"
  }, {
    path: "/prime/payment/billing-data",
    component: _1e1fe320,
    name: "prime-payment-billing-data"
  }, {
    path: "/error/:type",
    component: _5d2e4c5d,
    name: "error-type"
  }, {
    path: "/l/:id?",
    component: _1ba41b1f,
    name: "l-id"
  }, {
    path: "/sign-up/:code",
    component: _335837f3,
    name: "sign-up-code"
  }, {
    path: "/",
    component: _1bf3164c,
    name: "index"
  }, {
    path: "/:city",
    component: _7c05b956,
    name: "city"
  }, {
    path: "/:city/:store",
    component: _3e248132,
    name: "city-store"
  }, {
    path: "/:city/:store/banners",
    component: _3342d7ea,
    name: "city-store-banners"
  }, {
    path: "/:city/:store/productos-recomendados",
    component: _d5a7c67e,
    name: "city-store-productos-recomendados"
  }, {
    path: "/:city/:store/banners/:id",
    component: _1bb9edd5,
    name: "city-store-banners-id"
  }, {
    path: "/:city/:store/search/:q",
    component: _7779ee5c,
    name: "city-store-search-q"
  }, {
    path: "/:city/:store/banners/:id/:campaign",
    component: _354b3372,
    name: "city-store-banners-id-campaign"
  }, {
    path: "/:city/:store/:department",
    component: _5c898cd8,
    name: "city-store-department"
  }, {
    path: "/:city/:store/:department/:shelf",
    component: _0622d41c,
    name: "city-store-department-shelf"
  }, {
    path: "/:city/:store/:department/:shelf/:product",
    component: _8f80d54e,
    name: "city-store-department-shelf-product"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
