import Vue from 'vue';

import Vuex from 'vuex';

import VueCompositionAPI from '@vue/composition-api';

import actions from './actions';

import ui from './modules/ui';
import home from './modules/home';
import product from './modules/product';
import user from './modules/user';
import cart from './modules/cart';
import address from './modules/address';
import config from './modules/config';
import checkout from './modules/checkout';
import events from './modules/events';
import i18n from './modules/i18n';
import phone_validate from './modules/phone_validate';
import order_edit from './modules/order_edit';
import stories from './modules/stories';
import campaigns from './modules/campaigns';
import prime from './modules/prime';

Vue.use(Vuex);
const store = () => {
  return new Vuex.Store({
    modules: {
      home,
      ui,
      product,
      user,
      cart,
      address,
      config,
      checkout,
      events,
      i18n,
      phone_validate,
      order_edit,
      stories,
      campaigns,
      prime,
    },
    actions,
  });
};

Vue.use(VueCompositionAPI);

export default store;
