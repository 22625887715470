import { validateScheme } from '~/decorators/validateScheme';
import { GenericResponseType } from '~/interfaces/generic-response';
import { StoresParams } from '~/schemes/stores-params-schemes';
import $axios from '~/services/global-axios';

export class StoresEndpoint {
  @validateScheme
  static async getStoresEndpointV32({ cityId, latitude, longitude, cache }: StoresParams): GenericResponseType {
    try {
      let query =
        latitude && longitude
          ? `/3.2/stores?cityId=${cityId}&latitude=${latitude}&longitude=${longitude}`
          : `/3.2/stores?cityId=${cityId}`;

      if (!cache) {
        query = `${query}&time=${new Date().getTime()}`;
      }

      const response = await $axios.get(query);
      return { error: null, response };
    } catch (e) {
      return { response: null, error: e as any };
    }
  }
}
