import { COVERAGE_TYPES } from '~/constants/coverage-constants';
import { MARKETPLACE_BUSINESS_STATUS } from '~/constants/stores';
import { checkFeatureFlag, FEATURE_TOGGLES_KEYS, updateFeatureFlag } from '~/feature-flags/feature-toggles';
import { nuxtInstance } from '~/plugins/nuxt-instance';
import { splitFlogFlagService } from '~/services/split-store-flag';
import { getStoresUseCase } from '../stores/stores-usecase';

export async function validateCoverageUseCase({ storeSlug, cityId, latitude, longitude }) {
  const {
    store: { dispatch },
  } = nuxtInstance;

  if (checkFeatureFlag(FEATURE_TOGGLES_KEYS.SPLIT_STORE_GENERAL_TOGGLE)) {
    const splitStoreFlag = await splitFlogFlagService({ cityId });
    updateFeatureFlag(FEATURE_TOGGLES_KEYS.SPLIT_STORE_TOGGLE, splitStoreFlag);
  }
  const { data: stores, error } = await getStoresUseCase({ cityId, latitude, longitude, cache: false });

  const hasOneStoreAvailable = stores?.some(
    (store) =>
      store.businessStatus !== MARKETPLACE_BUSINESS_STATUS.CLOSED &&
      store.businessStatus !== MARKETPLACE_BUSINESS_STATUS.NOT_AVAILABLE,
  );

  if (hasOneStoreAvailable && error === null) {
    dispatch('home/setTemporalStores', stores);

    if (storeSlug) {
      const coverageValidForCurrentStore = stores.find((store) => storeSlug === store.slug) ?? null;
      if (coverageValidForCurrentStore?.businessStatus === MARKETPLACE_BUSINESS_STATUS.AVAILABLE) {
        return COVERAGE_TYPES.CURRENT_STORE_WITH_COVERAGE;
      } else if (coverageValidForCurrentStore?.businessStatus === MARKETPLACE_BUSINESS_STATUS.CLOSED) {
        return COVERAGE_TYPES.CURRENT_STORE_CLOSED;
      } else if (coverageValidForCurrentStore?.businessStatus === MARKETPLACE_BUSINESS_STATUS.NOT_AVAILABLE) {
        return COVERAGE_TYPES.CURRENT_STORE_WITHOUT_COVERAGE;
      }
      return COVERAGE_TYPES.CURRENT_STORE_NOT_FOUND;
    } else {
      return COVERAGE_TYPES.WITHOUT_STORE_SELECTED;
    }
  }
  return COVERAGE_TYPES.STORES_NOT_AVAILABLE;
}

